import { cva } from 'class-variance-authority';

export const variants = cva('text-black', {
    variants: {
        intent: {
            display: 'tracking-[-0.065em] font-bold ',
            heading: 'tracking-[-0.06em]',
            body: 'tracking-[-0.02em]',
        },
        size: {
            xl: 'text-[20px]',
            lg: 'text-[18px]',
            md: 'text-[16px]',
            sm: 'text-[14px]',
            xs: 'text-[12px]',
        },
    },
    defaultVariants: {
        intent: 'body',
        size: 'md',
    },
    compoundVariants: [
        {
            intent: 'display',
            size: 'xl',
            className: 'text-[124px] leading-[104px]',
        },
        {
            intent: 'display',
            size: 'lg',
            className: 'text-[80px] leading-[68px]',
        },
        {
            intent: 'display',
            size: 'md',
            className: 'text-[68px] leading-[56px]',
        },
        {
            intent: 'display',
            size: 'sm',
            className: 'text-[56px] leading-[48px]',
        },
        {
            intent: 'display',
            size: 'xs',
            className: 'text-[44px] leading-[36px]',
        },
        {
            intent: 'heading',
            size: 'xl',
            className: 'text-[40px] leading-[44px]',
        },
        {
            intent: 'heading',
            size: 'lg',
            className: 'text-[36px] leading-[40px]',
        },
        {
            intent: 'heading',
            size: 'md',
            className: 'text-[32px] leading-[36px]',
        },
        {
            intent: 'heading',
            size: 'sm',
            className: 'text-[28px] leading-[32px]',
        },
        {
            intent: 'heading',
            size: 'xs',
            className: 'text-[24px] leading-[28px]',
        },
        {
            intent: 'body',
            size: 'xl',
            className: 'text-[20px] leading-[32px]',
        },
        {
            intent: 'body',
            size: 'lg',
            className: 'text-[18px] leading-[28px]',
        },
        {
            intent: 'body',
            size: 'md',
            className: 'text-[16px] leading-[24px]',
        },
        {
            intent: 'body',
            size: 'sm',
            className: 'text-[14px] leading-[20px]',
        },
        {
            intent: 'body',
            size: 'xs',
            className: 'text-[12px] leading-[16px]',
        },
    ],
});
