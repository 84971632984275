import * as React from 'react';
import type { TypographyProps } from './types';
import { cn } from '~/common/utils/styles';
import { variants } from './variants';

export const Typography = ({
    children,
    as = 'div',
    className,
    intent,
    size,
    ...rest
}: TypographyProps) => {
    const As = as;
    return (
        <As className={cn(variants({ intent, size }), className)} {...rest}>
            {children}
        </As>
    );
};
